import { CheckAction } from '../action/check';
import {
  UploadCheckStatus,
  UploadCheckError,
  UploadSchema,
  UploadActiveSchema,
  InputType,
} from '../../firebase/schemas/upload';

export interface CheckState {
  activeListener: boolean;
  uploadProgress: number;
  isPauseBoxShown: boolean;
  pauseBoxShownTotal: number;
  upload: UploadSchema;
  active?: Record<string, UploadActiveSchema>;
}

export const initialState: CheckState = {
  activeListener: true,
  uploadProgress: 0,
  isPauseBoxShown: false,
  pauseBoxShownTotal: 0,
  upload: {
    isPublic: false,
    isStopping: false,
    code: '',
    status: 'idle' as UploadCheckStatus,
    error: undefined as UploadCheckError | undefined,
    fileName: '',
    fileSize: 0,
    type: InputType.Email,
    worker: 0,
    line: 0,
    valid: 0,
    invalid: 0,
    duplicate: 0,
    totalResult: 0,
    totalLive: 0,
    totalDead: 0,
    totalError: 0,
    totalUncheck: 0,
    totalData: 0,
    handlers: [],
    loader: {},
    // validatingAt: Date.now(),
    // validatedAt: Date.now(),
    // startedAt: Date.now(),
    // endedAt: Date.now(),
    // createdAt: Date.now(),
    createdBy: '',
  },
  active: undefined,
};

export default (state = initialState, action: CheckAction): CheckState => {
  switch (action.type) {
    case 'CHANGE_CHECK_TYPE':
      return { ...state, upload: { ...state.upload, type: action.value } };
    case 'SET_UPLOAD_PROGRESS':
      return { ...state, uploadProgress: action.progress };
    case 'SET_ACTIVE_LISTENER':
      return { ...state, activeListener: action.isActive };
    case 'SHOW_PAUSE_BOX_LISTENER':
      return {
        ...state,
        isPauseBoxShown: true,
        pauseBoxShownTotal: state.pauseBoxShownTotal + (state.activeListener ? 1 : 0),
        activeListener: false,
      };
    case 'HIDE_PAUSE_BOX_LISTENER':
      return {
        ...state,
        isPauseBoxShown: false,
        activeListener: true,
      };
    case 'CHANGE_CHECK_STATUS':
      return { ...state, upload: { ...state.upload, status: action.status } };
    case 'CHECK_IS_ERROR':
      return { ...state, upload: { ...state.upload, error: action.error } };
    case 'RESET_CHECK':
      return { ...initialState, upload: { ...initialState.upload, type: state.upload.type } };
    case 'SET_UPLOAD_DATA':
      return { ...state, upload: { ...initialState.upload, ...action.data } };
    case 'SET_ACTIVE_DATA':
      return { ...state, active: { ...state.active, [action.key]: action.data } };
    default:
      return state;
  }
};
