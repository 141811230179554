import { ChatAction } from '../action/chat';
import ChatSchema from '../../firebase/schemas/chat';
import { UserProfileSchema } from '../../firebase/schemas/user';
import uiChatConfig from '../../ui/chat';

export type ChatList = {
  uid: string;
  time: number;
  showTime: boolean;
  profile: UserProfileSchema;
  chats: Array<[string, string]>;
  lastKey: string;
};

export const initialState = {
  isChatBoxShown: false,
  isScrollButtonShown: false,
  typingValue: '',
  typeBlocked: {
    private: undefined as number | undefined,
    public: undefined as number | undefined,
  },
  chats: {
    private: {
      list: [] as Array<ChatList>,
      lastKey: null as string | null,
      lastUid: null as string | null,
      isLoaded: false,
    },
    public: {
      list: [] as Array<ChatList>,
      lastKey: null as string | null,
      lastUid: null as string | null,
      isLoaded: false,
    },
  },
};

export type ChatState = typeof initialState;

const chatListParser = (state: Array<ChatList>, data: Array<ChatSchema>): Array<ChatList> => {
  let groups: Array<ChatList> = [...state];
  let lastUid = (groups.length && groups[groups.length - 1].uid) || null;
  let lastTime = (groups.length && groups[groups.length - 1].time) || 0;

  for (let i = 0; i < data.length; i += 1) {
    const isTimeGrouping = lastTime >= data[i].createdAt - uiChatConfig.timeTolerant;
    if (lastUid === data[i].createdBy.uid && isTimeGrouping) {
      groups[groups.length - 1] = {
        ...groups[groups.length - 1],
        lastKey: data[i].key,
        chats: [...groups[groups.length - 1].chats, [data[i].key, data[i].content]],
      };
    } else {
      lastUid = data[i].createdBy.uid;
      lastTime = data[i].createdAt;

      groups = [
        ...groups,
        {
          uid: data[i].createdBy.uid,
          time: data[i].createdAt,
          showTime: !isTimeGrouping,
          profile: data[i].createdBy,
          chats: [[data[i].key, data[i].content]],
          lastKey: data[i].key,
        },
      ];
    }
  }

  return groups;
};

const chatReducer = (state = initialState, action: ChatAction): ChatState => {
  switch (action.type) {
    case 'SET_CHAT_BLOCKED':
      return {
        ...state,
        typeBlocked: {
          ...state.typeBlocked,
          [action.isPrivateChat ? 'private' : 'public']:
            action.until && Number(action.until) > Date.now() ? action.until : undefined,
        },
      };
    case 'TOGGLE_CHAT_BOX':
      return { ...state, isChatBoxShown: !state.isChatBoxShown };
    case 'SET_CHAT_TYPE':
      return { ...state, typingValue: action.value };
    // case 'TOGGLE_SCROLL_BUTTON':
    //   return { ...state, isScrollButtonShown: !state.isScrollButtonShown };
    case 'SHOW_SCROLL_BUTTON':
      return { ...state, isScrollButtonShown: true };
    case 'HIDE_SCROLL_BUTTON':
      return { ...state, isScrollButtonShown: false };
    case 'RESET_CHAT':
      return { ...initialState, isChatBoxShown: state.isChatBoxShown, ...action.any };
    case 'SET_PRIVATE_CHAT_LIST':
      return {
        ...state,
        chats: {
          ...state.chats,
          private: {
            list: chatListParser([], action.chats),
            lastKey: action.chats?.length ? action.chats[action.chats.length - 1].key : state.chats.private.lastKey,
            lastUid: action.chats?.length
              ? action.chats[action.chats.length - 1].createdBy.uid
              : state.chats.private.lastUid,
            isLoaded: true,
          },
        },
      };
    case 'SET_PUBLIC_CHAT_LIST':
      return {
        ...state,
        chats: {
          ...state.chats,
          public: {
            list: chatListParser([], action.chats),
            lastKey: action.chats?.length ? action.chats[action.chats.length - 1].key : state.chats.public.lastKey,
            lastUid: action.chats?.length
              ? action.chats[action.chats.length - 1].createdBy.uid
              : state.chats.public.lastUid,
            isLoaded: true,
          },
        },
      };
    case 'PUSH_PRIVATE_CHAT':
      return {
        ...state,
        chats: {
          ...state.chats,
          private: {
            ...state.chats.private,
            list: chatListParser(state.chats.private.list, [action.chat]),
            lastKey: action.chat.key,
            lastUid: action.chat.createdBy.uid,
          },
        },
      };
    case 'PUSH_PUBLIC_CHAT':
      return {
        ...state,
        chats: {
          ...state.chats,
          public: {
            ...state.chats.public,
            list: chatListParser(state.chats.public.list, [action.chat]),
            lastKey: action.chat.key,
            lastUid: action.chat.createdBy.uid,
          },
        },
      };
    case 'REMOVE_PRIVATE_CHAT': {
      const filteredList = state.chats.private.list
        .map((list) => ({
          ...list,
          chats: list.chats.filter((chat) => chat[0] !== action.key),
          lastKey: list.lastKey === action.key ? list.chats[list.chats.length - 2]?.[0] : list.lastKey,
        }))
        .filter((list) => list.chats.length > 0);

      return {
        ...state,
        chats: {
          ...state.chats,
          private: {
            ...state.chats.private,
            list: filteredList,
            lastKey: filteredList[filteredList.length - 1]?.lastKey,
            lastUid: filteredList[filteredList.length - 1]?.uid,
          },
        },
      };
    }
    case 'REMOVE_PUBLIC_CHAT': {
      const filteredList = state.chats.public.list
        .map((list) => ({
          ...list,
          chats: list.chats.filter((chat) => chat[0] !== action.key),
          lastKey: list.lastKey === action.key ? list.chats[list.chats.length - 2]?.[0] : list.lastKey,
        }))
        .filter((list) => list.chats.length > 0);

      return {
        ...state,
        chats: {
          ...state.chats,
          public: {
            ...state.chats.public,
            list: filteredList,
            lastKey: filteredList[filteredList.length - 1]?.lastKey,
            lastUid: filteredList[filteredList.length - 1]?.uid,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default chatReducer;
