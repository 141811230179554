import React from 'react';
import { ReactComponent as Logo } from '../../../assets/logo/logo.svg';
import { SVGWrapper } from './styles';

interface Props {
  size: number;
}

const MainLogo: React.FC<Props> = (props: Props) => {
  return (
    <SVGWrapper size={props.size}>
      <Logo />
    </SVGWrapper>
  );
};

export default MainLogo;
