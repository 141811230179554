import React from 'react';
import { DefaultTheme } from 'styled-components';
import { ThreeDots } from './styles';

interface Props {
  size: number;
  isDisabled?: boolean;
  variant?: keyof DefaultTheme['variant'];
}

const Dots: React.FC<Props> = (props: Props) => {
  return !props.isDisabled ? (
    <ThreeDots size={props.size} variant={props.variant}>
      <div />
      <div />
      <div />
    </ThreeDots>
  ) : null;
};

export default Dots;
