import { DoneAction } from '../action/done';
import { DoneSchema, UploadSchema, SpeedOutput } from '../../firebase/schemas/upload';

export interface DoneState {
  searchKey: string;
  selectedUid: string;
  selectedKey: string;
  selectedData: UploadSchema | null;
  doneEntries: [string, DoneSchema][] | null;
  speedOutput: SpeedOutput | null;
}

const initialState: DoneState = {
  searchKey: '',
  selectedUid: '',
  selectedKey: '',
  selectedData: null,
  doneEntries: null,
  speedOutput: null,
};

export default (state = initialState, action: DoneAction): DoneState => {
  switch (action.type) {
    case 'RESET_DONE':
      return { ...initialState };
    case 'SELECT_DONE':
      return { ...state, selectedUid: action.uid, selectedKey: action.key, selectedData: null };
    case 'SET_DONE':
      return {
        ...state,
        selectedData: {
          ...action.data,
          handlers: action.data.handlers ? Object.values(action.data.handlers) : action.data.handlers,
        },
      };
    case 'SET_SPEED_OUTPUT':
      return { ...state, speedOutput: action.value };
    case 'SET_SEARCH_VALUE':
      return { ...state, searchKey: action.value };
    case 'SET_DONE_LIST':
      return { ...state, doneEntries: Array.isArray(action.list) ? action.list.reverse() : action.list };
    default:
      return state;
  }
};
