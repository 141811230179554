import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

// redux packages
import { Provider } from 'react-redux';

// redux config
import store from './configs/redux/store';

import './index.css';

import App from './App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
