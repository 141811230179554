import styled from 'styled-components';

export const SVGWrapper = styled.div<{ size: number }>`
  display: inline-block;

  & > svg {
    vertical-align: middle;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;

    @media (max-width: 768px) {
      width: ${({ size }) => size * 0.75}px;
      height: ${({ size }) => size * 0.75}px;
    }
  }
`;
