import { UserAction } from '../action/user';
import UserSchema, { UserProfileSchema } from '../../firebase/schemas/user';
import { Order, OrderKey, Package, Discount, Booster } from '../../firebase/schemas/package';
import { Transaction, Topup } from '../../firebase/schemas/transaction';

const initialState = {
  isLoaded: false,
  isLoggedIn: false,
  userID: undefined as string | undefined,
  currentUser: undefined as UserSchema | undefined,
  profiles: {} as Record<string, UserProfileSchema>,
  presences: 0,
  totalChat: {
    public: 0,
    private: 0,
    total: 0,
  },
  totalRead: {
    public: 0,
    private: 0,
    total: 0,
  },
  totalUnread: {
    public: 0,
    private: 0,
    total: 0,
  },
  orders: undefined as
    | {
        pending: Record<OrderKey, boolean>;
        active: Record<OrderKey, Order | undefined>;
        past: Array<Order>;
      }
    | undefined,
  packages: undefined as Record<OrderKey, Package> | undefined,
  boosters: undefined as Record<string, Booster> | undefined,
  transactions: undefined as Record<string, Transaction[]> | null | undefined,
  topup: undefined as Topup | null | undefined,
  discount: {
    deposit: undefined as Discount | undefined,
    package: undefined as Discount | undefined,
  },
};

export type UserState = typeof initialState;

export default (state = initialState, action: UserAction): UserState => {
  switch (action.type) {
    case 'LOGGED_IN':
      return {
        ...state,
        isLoggedIn: true,
      };
    case 'UPDATE_USER':
      return {
        ...state,
        currentUser: action.user,
        isLoaded: true,
        userID: action.user ? action.user.uid : undefined,
        profiles: action.user
          ? {
              ...state.profiles,
              [action.user.uid]: action.user.profile,
            }
          : state.profiles,
      };
    case 'SET_USERS_PROFILE':
      return { ...state, profiles: { ...state.profiles, ...action.profiles } };
    case 'SET_USERS_PRESENCE':
      return { ...state, presences: action.total };
    case 'INC_TOTAL_PUBLIC_CHAT':
      return {
        ...state,
        totalChat: {
          ...state.totalChat,
          public: state.totalChat.public + action.total,
          total: state.totalChat.public + action.total + state.totalChat.private,
        },
        totalUnread: {
          ...state.totalUnread,
          public: state.totalChat.public + action.total - state.totalRead.public,
          total: state.totalChat.public + action.total + state.totalChat.private - state.totalRead.total,
        },
      };
    case 'INC_READ_PRIVATE_CHAT':
      return {
        ...state,
        totalRead: {
          ...state.totalRead,
          private: state.totalRead.private + action.total,
          total: state.totalRead.total + action.total,
        },
        totalUnread: {
          ...state.totalUnread,
          private: state.totalChat.private - state.totalRead.private + action.total,
          total: state.totalChat.total - state.totalRead.total + action.total,
        },
      };
    case 'INC_READ_PUBLIC_CHAT':
      return {
        ...state,
        totalRead: {
          ...state.totalRead,
          public: state.totalRead.public + action.total,
          total: state.totalRead.total + action.total,
        },
        totalUnread: {
          ...state.totalUnread,
          public: state.totalChat.public - (state.totalRead.public + action.total),
          total: state.totalChat.total - (state.totalRead.total + action.total),
        },
      };
    case 'INC_TOTAL_PRIVATE_CHAT':
      return {
        ...state,
        totalChat: {
          ...state.totalChat,
          private: state.totalChat.private + action.total,
          total: state.totalChat.private + action.total + state.totalChat.public,
        },
        totalUnread: {
          ...state.totalUnread,
          private: state.totalChat.private + action.total - state.totalRead.private,
          total: state.totalChat.private + action.total + state.totalChat.public - state.totalRead.total,
        },
      };
    case 'SET_TOTAL_PUBLIC_CHAT':
      return {
        ...state,
        totalChat: {
          ...state.totalChat,
          public: action.total,
          total: action.total + state.totalChat.private,
        },
        totalUnread: {
          ...state.totalUnread,
          public: action.total - state.totalRead.public,
          total: action.total + state.totalChat.private - state.totalRead.total,
        },
      };
    case 'SET_TOTAL_PRIVATE_CHAT':
      return {
        ...state,
        totalChat: {
          ...state.totalChat,
          private: action.total,
          total: action.total + state.totalChat.public,
        },
        totalUnread: {
          ...state.totalUnread,
          private: action.total - state.totalRead.private,
          total: action.total + state.totalChat.public - state.totalRead.total,
        },
      };
    case 'SET_READ_CHAT':
      return {
        ...state,
        totalRead: {
          ...state.totalRead,
          public: action.total.public,
          private: action.total.private,
          total: action.total.public + action.total.private,
        },
        totalUnread: {
          ...state.totalUnread,
          public: state.totalChat.public - action.total.public,
          private: state.totalChat.private - action.total.private,
          total: state.totalChat.total - (action.total.public + action.total.private),
        },
      };
    case 'RESET_USER':
      return {
        ...initialState,
        isLoaded: state.isLoaded,
        profiles: state.profiles,
        presences: state.presences,
      };
    case 'SET_ORDERS':
      return {
        ...state,
        orders: {
          pending: action.orders?.pending || {},
          active: action.orders?.active || {},
          past: action.orders?.past || {},
        },
      };
    case 'SET_PACKAGES':
      return {
        ...state,
        packages: action.packages,
      };
    case 'SET_BOOSTERS':
      return {
        ...state,
        boosters: action.boosters,
      };
    case 'SET_TRANSACTIONS':
      return {
        ...state,
        transactions: action.transactions,
      };
    case 'SET_TOPUP':
      return {
        ...state,
        topup: action.topup,
      };
    case 'SET_DEPOSIT_DISCOUNT':
      return {
        ...state,
        discount: {
          ...state.discount,
          deposit: action.discount,
        },
      };
    case 'SET_PACKAGE_DISCOUNT':
      return {
        ...state,
        discount: {
          ...state.discount,
          package: action.discount,
        },
      };
    default:
      return state;
  }
};
