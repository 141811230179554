import { CacheAction } from '../action/cache';
import { RealChatSchema } from '../../firebase/schemas/chat';
import UserSchema from '../../firebase/schemas/user';

const initialState = {
  publicChats: [] as [string, RealChatSchema][],
  user: undefined as UserSchema | undefined,
};

export type CacheState = typeof initialState;

export default (state = initialState, action: CacheAction): CacheState => {
  switch (action.type) {
    case 'PUSH_CHAT_CACHE': {
      return { ...state, publicChats: [...state.publicChats, [action.key, action.chat]] };
    }
    case 'REMOVE_CHAT_CACHE': {
      return { ...state, publicChats: state.publicChats.filter(([key]) => key !== action.key) };
    }
    case 'SET_CHATS_CACHE': {
      return { ...state, publicChats: action.entries };
    }
    case 'SET_USER_CACHE': {
      return { ...state, user: action.user };
    }
    default:
      return state;
  }
};
