import React from 'react';
import Dots from '../../atoms/loader/Dots';
import MainLogo from '../../atoms/logo/MainLogo';
import { Wrapper } from './styles';

interface Props {
  size: number;
  full?: boolean;
  logo?: boolean;
  text?: string;
}

const PageLoader: React.FC<Props> = (props) => {
  return (
    <Wrapper isFull={props.full}>
      {props.logo && <MainLogo size={props.size * 4} />}
      <Dots size={props.size} />
      {props.text && <small>{props.text}</small>}
    </Wrapper>
  );
};

export const fullLoadingWithText = (text: string) => <PageLoader size={10} full logo text={text} />;

export default PageLoader;
