export type UploadCheckStatus =
  | 'idle'
  | 'loading'
  | 'uploading'
  | 'validating'
  | 'validated'
  | 'pending'
  | 'sending'
  | 'splitting'
  | 'check'
  | 'recheck'
  | 'building'
  | 'done';

export type ActiveCheckStatus = 'PENDING' | 'RUNNING' | 'PAUSED' | 'COMPLETED' | 'STOPPED' | 'DOWN';

export enum ResultCode {
  UNCHECK,
  LIVE,
  DEAD,
  ERROR,
}

export interface UploadCheckError {
  code: string;
  retryable: boolean;
}

export interface UploadActiveSchema {
  isConnected: boolean;
  code: string;
  status: ActiveCheckStatus;
  totalWorker: {
    reserved: number;
    active: number;
    pending: number;
  };
  totalResult: number;
  totalNew: number;
  totalLive: number;
  totalDead: number;
  totalError: number;
  totalUncheck: number;
  speed: number;
  interval: number;
  newResult: Array<{ code: ResultCode; input: string; data: '' }>;
  startedAt: number;
  startedBy: string;
}

export enum InputType {
  Email = 'email' as any,
  Account = 'account' as any,
  Card = 'card' as any,
  Phone = 'phone' as any,
}
export interface SubCheckInfo {
  key: string;
  total: number;
  worker: number;
  isHandled?: boolean;
  isDone?: boolean;
  isUploaded?: boolean;
  startedBy?: string;
}

export interface UploadSchema {
  isPublic: boolean;
  isStopping: boolean;
  recheck?: {
    type: 'data' | 'error';
    // code: string;
    total: number;
    // options: any;
    requestedAt: number;
    status: 'pending' | 'check' | 'building' | 'done';
  };
  loader: Record<string, SubCheckInfo>;
  code: string;
  fileName: string;
  fileSize: number;
  status: UploadCheckStatus;
  worker: number;
  type: InputType;
  line: number;
  valid: number;
  invalid: number;
  duplicate: number;
  totalResult: number;
  totalLive: number;
  totalDead: number;
  totalError: number;
  totalUncheck: number;
  totalData: number;
  disableErrorRecheck?: boolean;
  buildCount?: number;
  error?: UploadCheckError;
  domains?: [string, number][];
  startedAt?: number;
  endedAt?: number;
  buildedAt?: number;
  download?: {
    count: number;
    last: {
      name: string;
      photoURL: string;
    };
  };
  handlers: Array<{
    code: string;
    initializedAt: number;
    announcedAt: number;
    startedBy: string;
    ipAddress: string;
  }>;
  createdAt?: number;
  createdBy: string;
  validatingAt?: number;
  validatedAt?: number;
}

export interface DoneSchema {
  uid: string;
  owner: string;
  color: string;
  fileName: string;
  fileSize: number;
  isNew: boolean;
}

interface SpeedTick {
  time: number;
  tick: number;
}

export interface SpeedOutput {
  main: {
    minimum: number;
    average: number;
    maximum: number;
    ticks: Array<SpeedTick>;
  };
  alternate: {
    minimum: number;
    average: number;
    maximum: number;
    ticks: Array<SpeedTick>;
  };
}

export interface RecheckSchema {
  status: ActiveCheckStatus;
  requestedAt: number;
}
