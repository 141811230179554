import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { save, load } from 'redux-localstorage-simple';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import rootReducer from '../reducer';
import { initialState as checkInitialState } from '../reducer/check';

const store = createStore(
  rootReducer,
  load({
    states: ['setting', 'cache'],
    preloadedState: {
      check: { ...checkInitialState, upload: { ...checkInitialState.upload }, active: { ...checkInitialState.active } },
    },
  }),
  composeWithDevTools(
    applyMiddleware(
      save({
        states: ['setting', 'cache'],
        debounce: 500,
      }),
      thunk,
    ),
  ),
);

export default store;
