import { ServerAction } from '../action/server';
import { Summary, CheckSummary, SiteConfig } from '../../firebase/schemas/server';

const initialState = {
  isConnected: false,
  checkSummary: {
    totalDone: 0,
    totalChecking: 0,
    totalWorker: 0,
    totalUploaded: 0,
  } as CheckSummary,
  summary: {
    totalUser: 0,
    totalServer: {},
    totalPending: {},
  } as Summary,
  siteconfig: {
    discount: {
      package: null,
      deposit: null,
    },
    maintenance: {
      enabled: false,
    },
    suspend: {
      publicChat: false,
      check: false,
      topupMethod: {},
    },
  } as SiteConfig,
  usdRate: 0,
};

export type ServerState = typeof initialState;

export default (state = initialState, action: ServerAction): ServerState => {
  switch (action.type) {
    case 'SET_USD_RATE':
      return { ...state, usdRate: action.rupiah };
    case 'SERVER_CONNECTED':
      return { ...state, isConnected: true };
    case 'SERVER_DISCONNECT':
      return { ...state, isConnected: false };
    case 'SET_CHECK_SUMMARY':
      return { ...state, checkSummary: action.checkSummary };
    case 'SET_SUMMARY':
      return {
        ...state,
        summary: {
          totalUser: action.summary.totalUser || state.summary.totalUser,
          totalServer: action.summary.totalServer || state.summary.totalServer,
          totalPending: action.summary.totalPending || state.summary.totalPending,
        },
      };
    case 'SET_SITE_CONFIG':
      return {
        ...state,
        siteconfig: {
          ...state.siteconfig,
          ...action.config,
        },
      };
    default:
      return state;
  }
};
