import { combineReducers } from 'redux';

import settingReducer, { SettingState } from './setting';
import serverReducer, { ServerState } from './server';
import checkReducer, { CheckState } from './check';
import userReducer, { UserState } from './user';
import chatReducer, { ChatState } from './chat';
import doneReducer, { DoneState } from './done';
import cacheReducer, { CacheState } from './cache';

export interface RootState {
  user: UserState;
  server: ServerState;
  setting: SettingState;
  chat: ChatState;
  check: CheckState;
  done: DoneState;
  cache: CacheState;
}

const rootReducer = combineReducers({
  user: userReducer,
  server: serverReducer,
  setting: settingReducer,
  chat: chatReducer,
  check: checkReducer,
  done: doneReducer,
  cache: cacheReducer,
});

export default rootReducer;
