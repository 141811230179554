import React from 'react';
import loadable from '@loadable/component';
import { fullLoadingWithText } from './components/molecules/page/PageLoader';
import withClearCache from './withClearCache';

const FirebaseInitalizer = loadable(() => import('./components/organism/init/FirebaseInitalizer'), {
  fallback: fullLoadingWithText('Downloading Resources'),
});

const Pages = loadable(() => import('./pages'), {
  fallback: fullLoadingWithText('Loading Pages'),
});

const MainApp: React.FC = () => {
  return (
    <FirebaseInitalizer>
      <Pages />
    </FirebaseInitalizer>
  );
};

const ClearCacheComponent = withClearCache(MainApp);

const App: React.FC = () => {
  return <ClearCacheComponent />;
};

export default App;
