import { SettingAction } from '../action/setting';

const initialState = {
  isDarkMode: false,
  isChatMuted: false,
  isPrivateChat: false,
  isNotificationEnabled: false,
};

export type SettingState = typeof initialState;

export default (state = initialState, action: SettingAction): SettingState => {
  switch (action.type) {
    case 'TOOGLE_DARK_MODE':
      return { ...state, isDarkMode: !state.isDarkMode };
    case 'TOOGLE_CHAT_MUTED':
      return { ...state, isChatMuted: !state.isChatMuted };
    case 'TOGGLE_PRIVATE_CHAT':
      return { ...state, isPrivateChat: !state.isPrivateChat };
    case 'SET_NOTIFICATION_ENABLED':
      return { ...state, isNotificationEnabled: action.value };
    default:
      return state;
  }
};
